import {disableScroll, enableScroll} from './services/scroll-helpers';
import {isMacLike} from './website-ui/helpers/device-recognition';

const TIMEOUT_CLOSE_MENU = 500;

const $headerMenuBurger = document.getElementById('header__menu-burger');
const $menuListContainer = document.getElementById('navigation__menu-container');

if ($menuListContainer) {
  $headerMenuBurger?.addEventListener('click', () => {
    const isStartPage = window.pageYOffset === 0;

    if ($menuListContainer.classList.contains('navigation__menu-container--open')) {
      $menuListContainer.classList.remove('navigation__menu-container--open');
      $headerMenuBurger.classList.remove('header-menu-burger--open');

      if (isStartPage) {
        setTimeout(() => {
          $menuListContainer.classList.remove('navigation__menu-container--open-start');
        }, TIMEOUT_CLOSE_MENU);
      }

      enableScroll();
    } else {
      isStartPage && $menuListContainer.classList.add('navigation__menu-container--open-start');
      $menuListContainer.classList.add('navigation__menu-container--open');
      $headerMenuBurger.classList.add('header-menu-burger--open');
      disableScroll();
    }
  });
}

const LINK_APP_STORE = 'https://apps.apple.com/kz/app/nur-kz-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D0%B8-%D0%BA%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD%D0%B0/id830230766?utm_source=nur.kz&utm_medium=organic&utm_campaign=app_install&utm_content=app_install_header_banner\n'; /*eslint-disable-line*/

if (isMacLike) {
  const $bannerLink = document.querySelector('.js-branding-top');

  $bannerLink?.setAttribute('href', LINK_APP_STORE);
}
